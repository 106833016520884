import { Editor } from '@tiptap/react';
import clsx from 'classnames';
import { Undo } from './toolbar-icons/Undo';
import { Redo } from './toolbar-icons/Redo';
import { OrderedList } from './toolbar-icons/OrderedList';
import { Paragraph } from './toolbar-icons/Paragraph';
import { Strikethrough } from './toolbar-icons/Strikethrough';
import { HorizontalBreak } from './toolbar-icons/HorizontalBreak';
import { ClearFormatting } from './toolbar-icons/ClearFormatting';
import { AddImage } from './toolbar-icons/AddImage';
import { AlignCenter } from './toolbar-icons/AlignCenter';
import { AlignLeft } from './toolbar-icons/AlignLeft';
import { AlignRight } from './toolbar-icons/AlignRight';
import { Highlight } from './toolbar-icons/Highlight';
import { Hyperlink } from './toolbar-icons/Hyperlink';
import { H1 } from './toolbar-icons/H1';
import { H2 } from './toolbar-icons/H2';
import { H3 } from './toolbar-icons/H3';
import { H4 } from './toolbar-icons/H4';
import { H5 } from './toolbar-icons/H5';
import { H6 } from './toolbar-icons/H6';
import { useEffect, useRef, useState } from 'react';
import { btnReset } from '@/utils/constants';
import classNames from 'classnames';
import {
  BoldIcon,
  FontColorIcon,
  ItalicIcon,
  UnderlineIcon,
  UnorderedListIcon,
} from './toolbar-icons/NewToolbarIcons';
import { twMerge } from 'tailwind-merge';

const buttonReset = btnReset + ' hover:tw-bg-[#111C360F] '
import { ColorPicker } from '../../WidgetProperties/ColorPicker';

interface IToolbarMenu {
  uploadImageToS3AndGetLink: any;
  disabled: boolean;
  allowedEditorOptions: any[];
  removeEditorOptions: any[];
  editor: Editor;
  showFormattors: boolean;
  className: string;
  renderTextFormattersInSelectionMode: boolean;
}

export const ToolbarMenu = ({
  uploadImageToS3AndGetLink = null,
  disabled = false,
  allowedEditorOptions = [],
  removeEditorOptions = [],
  editor,
  showFormattors = true,
  className = '',
  // renderTextFormattersInSelectionMode = false,
}: Partial<IToolbarMenu>) => {
  // const { editor } = useCurrentEditor();

  // useEffect(() => {
  //   updateEditorRef(editor);
  // }, [editor]);

  if (!editor) {
    return null;
  }

  const editorChain = () => editor.chain().focus();

  const addImage = async (e) => {
    if (!uploadImageToS3AndGetLink) {
      return;
    }
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageUrl = await uploadImageToS3AndGetLink('img', file);
      if (imageUrl) {
        // editorChain().setImage({ src: imageUrl }).run();
        editorChain()
          .insertContent([
            {
              type: 'image',
              attrs: {
                src: imageUrl,
              },
            },
          ])
          .run();
      }
    }
  };

  const handleTextSelection = () => {
    editor.view.state.selection.empty &&
      editor.state.doc.textContent.trim().length &&
      editor.commands.selectAll();
  };

  const menuOptions = {
    bold: (
      <button
        onClick={(e) => {
          e.preventDefault();
          handleTextSelection();
          editorChain().toggleBold().run();
        }}
        disabled={disabled}
        className={clsx(buttonReset, 'tw-h-[24px] tw-overflow-hidden tw-rounded-[4px]')}
      >
        <BoldIcon
          color={editor.isActive('bold') && '#2B74D8'}
          className={twMerge(editor.isActive('bold') ? ' tw-bg-[#2B74D81F]' : '')}
        />
      </button>
    ),
    italic: (
      <button
        onClick={(e) => {
          e.preventDefault();
          handleTextSelection();
          editorChain().toggleItalic().run();
        }}
        disabled={disabled}
        className={clsx(buttonReset, 'tw-h-[24px] tw-overflow-hidden tw-rounded-[4px]')}
      >
        <ItalicIcon
          color={editor.isActive('italic') && '#2B74D8'}
          className={twMerge(editor.isActive('italic') ? ' tw-bg-[#2B74D81F]' : '')}
        />
      </button>
    ),
    underline: (
      <button
        onClick={(e) => {
          e.preventDefault();
          handleTextSelection();
          editorChain().toggleUnderline().run();
        }}
        disabled={disabled}
        className={clsx(buttonReset, 'tw-h-[24px] tw-overflow-hidden tw-rounded-[4px]')}
      >
        <UnderlineIcon
          color={editor.isActive('underline') && '#2B74D8'}
          className={twMerge(editor.isActive('underline') ? ' tw-bg-[#2B74D81F]' : '')}
        />
      </button>
    ),
    strike: (
      <button
        onClick={(e) => {
          e.preventDefault();
          handleTextSelection();
          editorChain().toggleStrike().run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('strike') ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]' : ''
        )}
      >
        <Strikethrough />
      </button>
    ),
    image: (
      <div
        className="dib ds-m4 relative ds-p4 ds-pv2 image-wrapper"
        style={{
          opacity: disabled ? 0.3 : 1,
        }}
      >
        <input
          id="file-upload"
          type="file"
          className="dn"
          accept=".png, .jpg, .jpeg"
          style={{ maxWidth: '100%' }}
          onChange={addImage}
          title="as"
          name="as"
          disabled={disabled}
        />
        <label htmlFor="file-upload">
          <AddImage width={18} />
        </label>
      </div>
    ),
    para: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().setParagraph().run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('paragraph') ? 'tw-rounded-[4px] tw-bg-[#e0e0e0]' : ''
        )}
      >
        <Paragraph />
      </button>
    ),
    h1: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 1 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 1 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H1 />
      </button>
    ),
    h2: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 2 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 2 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H2 />
      </button>
    ),
    h3: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 3 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 3 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H3 />
      </button>
    ),
    h4: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 4 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 4 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H4 />
      </button>
    ),
    h5: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 5 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 5 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H5 />
      </button>
    ),
    h6: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleHeading({ level: 6 }).run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('heading', { level: 6 })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <H6 />
      </button>
    ),
    ul: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleBulletList().run();
        }}
        disabled={disabled}
        className={clsx(buttonReset, 'tw-h-[24px] tw-overflow-hidden  tw-rounded-[4px]')}
      >
        <UnorderedListIcon
          color={editor.isActive('bulletList') && '#2B74D8'}
          className={twMerge(editor.isActive('bulletList') ? ' tw-bg-[#2B74D81F]' : '')}
        />
      </button>
    ),
    ol: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().toggleOrderedList().run();
        }}
        disabled={disabled}
        className={clsx(
          buttonReset,
          editor.isActive('orderedList') ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]' : ''
        )}
      >
        <OrderedList />
      </button>
    ),
    hr: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editorChain().setHorizontalRule().run();
        }}
        className={buttonReset}
        disabled={disabled}
      >
        <HorizontalBreak />
      </button>
    ),
    alignL: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.isActive({ textAlign: 'left' })
            ? editorChain().unsetTextAlign().run()
            : editorChain().setTextAlign('left').run();
        }}
        className={clsx(
          buttonReset,
          editor.isActive({ textAlign: 'left' }) ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]' : ''
        )}
      >
        <AlignLeft />
      </button>
    ),
    alignC: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.isActive({ textAlign: 'center' })
            ? editorChain().unsetTextAlign().run()
            : editorChain().setTextAlign('center').run();
        }}
        className={clsx(
          buttonReset,
          editor.isActive({ textAlign: 'center' })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <AlignCenter />
      </button>
    ),
    alignR: (
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.isActive({ textAlign: 'right' })
            ? editorChain().unsetTextAlign().run()
            : editorChain().setTextAlign('right').run();
        }}
        className={clsx(
          buttonReset,
          editor.isActive({ textAlign: 'right' })
            ? 'tw-rounded-[4px] tw-bg-[#dcdcdc]'
            : ''
        )}
      >
        <AlignRight />
      </button>
    ),
    fontColor: (
      <ColorTool
        Image={
          <FontColorIcon
            chosenColor={editor.getAttributes('textStyle').color}
            // color={editor.getAttributes('textStyle').color}
            // className={twMerge(editor.isActive('bold') ? ' tw-bg-[#2B74D81F]' : '')}
          />
        }
        editorChain={editorChain}
        itemKey="color"
        editor={editor}
      />
    ),
    highlight: (
      <ColorTool
        Image={<Highlight />}
        editorChain={editorChain}
        itemKey="highlight"
        editor={editor}
      />
    ),
    hyperlink: <HyperLink editorChain={editorChain} editor={editor} />,
  };

  const renderList = (() => {
    const allItems = Object.keys(menuOptions);
    const itemsToRender = allItems.reduce((acc, cur) => {
      if (allowedEditorOptions?.length) {
        if (allowedEditorOptions.includes(cur)) {
          acc.push(cur);
        }
      } else if (!removeEditorOptions.includes(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
    return itemsToRender;
  })();

  return (
    <div className={classNames('toolbar-wrapper', className)}>
      <div className="tw-flex tw-items-center">
        {renderList.map((renderItem) => menuOptions[renderItem])}
      </div>
      {showFormattors && (
        <div className="flex justify-end items-center">
          <button
            onClick={() => {
              editorChain().clearNodes().run();
            }}
            className={buttonReset}
            disabled={disabled}
          >
            <ClearFormatting width={12} />
          </button>
          <button
            onClick={() => {
              editorChain().undo().run();
            }}
            disabled={disabled}
            className={buttonReset}
          >
            <Undo width={12} />
          </button>
          <button
            onClick={() => {
              editorChain().redo().run();
            }}
            disabled={disabled}
            className={buttonReset}
          >
            <Redo width={12} />
          </button>
        </div>
      )}
    </div>
  );
};

function ColorTool({ Image, editor, editorChain, itemKey }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if (!e.composedPath()?.includes(tooltipRef.current)) {
        e.stopPropagation();
        closeTooltip();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const toggleTooltip = () => setShowTooltip(!showTooltip);

  const closeTooltip = () => setShowTooltip(false);

  return (
    <div
      className="tw-relative tw-h-[24px] tw-rounded-[4px] hover:!tw-bg-[#111C360F]"
      ref={tooltipRef}
    >
      {/* <input
        className="tw-absolute !tw-cursor-pointer tw-border-none tw-opacity-0"
        type="color"
        onChange={(event: any) => {
          event.preventDefault();
          editor.view.state.selection.empty && editor.commands.selectAll();
          itemKey === 'color'
            ? editorChain().setColor(event.target?.value).run()
            : editorChain()
                .setHighlight({ color: event.target?.value as any })
                .run();
        }}
        value={editor.getAttributes('textStyle').color}
        data-testid={itemKey === 'color' ? 'setColor' : 'setHighlight'}
      /> */}
      <ColorPicker
        onColorSubmit={(_, hex) => {
          // handleColorChange(e, hex, data, parentConfig, key)
          editor.view.state.selection.empty && editor.commands.selectAll();
          itemKey === 'color'
            ? editorChain().setColor(hex).run()
            : editorChain()
                .setHighlight({ color: hex as any })
                .run();
        }}
        customStyle={{
          background: 'none',
          width: 'max-content',
          height: 'auto',
        }}
        name={'Choose color'}
        value={editor.getAttributes('textStyle').color || '#000000'}
        showColorPicker={showTooltip}
        setShowColorPicker={setShowTooltip}
      >
        {/* <button
        onClick={(e) => {
          e.preventDefault();
          toggleTooltip();
        }}
        className={clsx(
          btnReset,
          'tw-h-[24px] tw-overflow-hidden tw-rounded-[4px] hover:!tw-bg-[#111C360F]'
        )}
      > */}
        {Image}
        {/* </button> */}
      </ColorPicker>
    </div>
  );
}

function HyperLink({ editor, editorChain }) {
  const ref = useRef(null);
  const [hyperlink, setHyperlink] = useState('');
  const [isPreviousHyperlinkSet, setIsPreviousHyperlinkSet] = useState(false);
  const [showHyperlinkTooltip, setShowHyperlinkTooltip] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref.current?.contains(e.target)) {
        return;
      }
      setHyperlink('');
      setShowHyperlinkTooltip(false);
    };

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const removeHyperlink = () => {
    editorChain().extendMarkRange('link').unsetLink().run();
    setHyperlink('');
    setShowHyperlinkTooltip(false);
  };

  const saveHyperlink = () => {
    if (hyperlink?.trim()) {
      editorChain()
        .extendMarkRange('link')
        .setLink({ href: hyperlink.trim(), target: '_blank' })
        .run();
      setShowHyperlinkTooltip(false);
    } else {
      removeHyperlink();
    }
  };

  const toggleTooltip = () => {
    const previousUrl = editor.getAttributes('link').href;
    if (previousUrl) {
      setHyperlink(previousUrl || '');
    }
    if (previousUrl && !isPreviousHyperlinkSet) {
      setIsPreviousHyperlinkSet(true);
    }
    if (!previousUrl && isPreviousHyperlinkSet) {
      setIsPreviousHyperlinkSet(false);
    }
    setShowHyperlinkTooltip((val) => !val);
  };

  return (
    <div className="tw-relative tw-inline-block" ref={ref}>
      <button onClick={toggleTooltip} className={buttonReset}>
        <Hyperlink />
      </button>
      {showHyperlinkTooltip && (
        <div
          className="tw-absolute tw-z-[5] tw-flex tw-items-center tw-bg-[#f7f7f7] tw-p-[12px]"
          style={{ top: '100%' }}
        >
          <input
            value={hyperlink}
            className="ma0"
            onChange={(e) => setHyperlink(e.target.value)}
          />
          <button
            onClick={saveHyperlink}
            className="ds-ml12 ma0 ds-text-[#3D6AFD] tw-bg-transparent"
          >
            Add
          </button>
          <button
            onClick={removeHyperlink}
            // disabled={!isPreviousHyperlinkSet}
            className={clsx(
              'ds-ml12 ma0 ds-text-[#3D6AFD] tw-bg-transparent'
              // { isPreviousHyperlinkSet: "o-10" }
            )}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}
